<template>
    <div id="mm-signed-out" class="container-fluid">
        <div class="row d-flex">
            <section class="-left-section col-12 col-lg-6 px-0">
                <div class="-container mx-auto px-lg-0">
                    <h2>
                        Wanderlist is the premier travel dreaming and planning app for luxury travelers.
                    </h2>
                    <ul class="list-unstyled mt-lg-4">
                        <li class="d-flex">
                            <i class="icon-heart-outline lh-base"></i>
                            <p class="flex-fill ms-1">
                                Tell us how you love to travel, we&#39;ll take it from there
                            </p>
                        </li>
                        <li class="d-flex">
                            <i class="icon-map-marker-ut lh-base"></i>
                            <p class="flex-fill ms-1">
                                Explore our vetted catalog of the very best places and experiences in the world
                            </p>
                        </li>
                        <li class="d-flex">
                            <i class="icon-service-provider-01 lh-base"></i>
                            <p class="flex-fill ms-1">
                                Experience the world the way you want to with your favorite people
                            </p>
                        </li>
                    </ul>
                </div>
                <div class="-ctas-content col-12 d-lg-none">
                    <div class="-wrapper d-grid gap-2">
                        <img src="/images/vir-wanderlist-logo-white.svg" width="204" height="24" alt="Virtuoso Wanderlist logo" class="mx-auto" />
                        <a :href="signInUrl" class="btn btn-primary-emphasis -sign-in">
                            Sign In
                        </a>
                        <a :href="signInUrl" class="btn btn-primary-regular -create-account">
                            Start Dreaming
                        </a>
                    </div>
                </div>
            </section>
            <section class="-right-section col-6 d-none d-lg-block">
                <div class="-content mx-auto pt-8">
                    <img src="/images/vir-wanderlist-logo.svg" width="204" height="24" alt="Virtuoso Wanderlist logo" />
                    <h2 class="fw-bold mt-3">
                        Welcome Back!
                    </h2>
                    <p>
                        Already have an account? Sign in to continue where you left off.
                    </p>

                    <a :href="signInUrl" class="btn btn-primary-emphasis d-block mt-2">
                        Sign In
                    </a>

                    <p class="text--default mt-6">
                        New to Virtuoso? <a :href="signUpUrl">Create a free account</a> and start dreaming.
                    </p>
                </div>
            </section>
        </div>
    </div>
</template>


<script lang="ts">
    /**
    * Title: mega-menu-signedout.vue
    * Description: Mega-Menu Signed out user state Vue component.
    * Author: Rob Hill
    * Date: 07/18/2023
    * Version: Mega Menu Phase 1
    */
    import { defineComponent } from "vue";
    export default defineComponent({
        data() {
            return {
                signInUrl: `${window.VIRTUOSO.enterpriseServicesUrl}/LogIn/SignIn.aspx?ReturnUrl=${encodeURIComponent(window.location.href)}`,
                signUpUrl: `${window.VIRTUOSO.enterpriseServicesUrl}/LogIn/SignUp?ReturnUrl=${encodeURIComponent(window.location.href)}`
            };
        }
    });
</script>
