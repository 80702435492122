<template>
    <ul v-if="users.length" class="companions list-unstyled">
        <li v-for="(companion, index) in displayedCompanionCircles" :key="index" :class="['companion m-0']" :style="{ backgroundColor: companion.avatarColor || '#EFEFEF', boxShadow: `0 0 0 1px ${companion.avatarColor || 'rgba(239, 239, 239, 0.1)'}`}" :title="companion.name">{{ companion.initials }}</li>
        <li v-if="showOverFlowUsers" class="companion m-0 -bg-count" title="count">{{ companionOverflowText }}</li>
    </ul>
</template>


<script lang="ts">
    import { WanderlistCompanion } from "interfaces/wanderlist";
    import { PropType, defineComponent } from "vue";

    export default defineComponent({
        props: {
            users: {
                type: Array as PropType<WanderlistCompanion[]>,
                default: undefined
            }
        },
        data() {
            return {
                showOverFlowUsers: (this.users.length > 4),
                companionOverflowText: `+ ${(this.users.length) - 4}`
            };
        },
        computed: {
            displayedCompanionCircles: function (): WanderlistCompanion[] {
                return (this.showOverFlowUsers) ? this.users.slice(3) : this.users;
            }
        }
    });
</script>
