/**
 * Title: mega-menu
 * Description: functions to handle the non-Vue "wrapper" pieces,
 * showing and hiding, and loading
 * the mega-menu Vue component.
 * Author: Rob Hill
 * Date: 07/12/2023
 * Version: Mega Menu Phase 1
 */
import "jquery";
import { createApp } from "vue";
import { mountApp } from "vue-app";
import MegaMenuComponent from "vue-components/wanderlist/mega-menu.vue";

let isInitialized = false;

/**
 * Feature flag check in here -- if enabled, initializes/opens the Mega Menu, otherwise passes off to /wanderlist
 */
export function enableMegaMenu(): void {
    $("#header-wanderlist-icon").click((event) => {
        event.preventDefault();
        if (!isInitialized) {
            initMegaMenu();
            return;
        }
        toggleMegaMenu();
    });
}

function initMegaMenu(): void {
    isInitialized = true;

    const app = createApp(MegaMenuComponent);
    mountApp(app, "mega-menu-container");
}

function openMegaMenu(): void {
    // Fake the scrollbar width, if present, to prevent shift
    const scrollbarWidth = Math.abs(window.innerWidth - document.documentElement.clientWidth);
    $("body, .masthead-container, .nav-container, #mega-menu-container").css("paddingRight", `${scrollbarWidth}px`);

    $("#mega-menu-container").stop().slideDown(200);
}

function closeMegaMenu(): void {
    // Reset scrollbar offset
    $("body, .masthead-container, .nav-container, #mega-menu-container").css("paddingRight", "0");

    $("#mega-menu-container").slideUp(200);
}

export function toggleMegaMenu(): void {
    ($("body").hasClass("mega-menu-open")) ? closeMegaMenu() : openMegaMenu();
    $("#header-wanderlist-icon").toggleClass("-active");
    $("body").toggleClass("mega-menu-open mega-menu-closed");
}
