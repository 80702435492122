<template>
    <a :href="listCardLink">
        <div class="list-card-container">
            <div class="-images">
                <div class="-primary">
                    <img :src="primaryImage.imageUrl" :alt="primaryImage.altText">
                </div>
                <div v-if="extraImages.length" :class="'-extras -extras-' + (extraImages.length)">
                    <div v-for="(img, imgIndex) in extraImages" :key="imgIndex"><img :src="img.imageUrl" :alt="img.altText"></div>
                </div>
            </div>
            <div class="-destination-set">
                <template v-if="destinationSet.length">
                    <i class="icon-map-marker-ut"></i>
                    {{ destinationMoreText }}
                </template>
                <div class="ms-auto me-2">
                    <companion-circles-component :users="wlUsers"></companion-circles-component>
                </div>
            </div>
            <div class="-truncated-title" v-html="listNameText"></div>
        </div>
    </a>
</template>


<script lang="ts">
    /**
    * Title: list-card.vue
    * Description: list-card Vue component for mega-menu feature.
    * Author: Rob Hill
    * Date: 07/27/2023
    * Version: Mega Menu Phase 1
    */
    import { CaptionedImage } from "interfaces/image";
    import { WanderlistListCard } from "interfaces/wanderlist";
    import { cobrandLink, escapeHTML } from "virtuoso-shared-web-ui";
    import { PropType, defineComponent } from "vue";
    import CompanionCirclesComponent from "vue-components/wanderlist/companion-circles.vue";

    export default defineComponent({
        components: {
            CompanionCirclesComponent
        },

        props: {
            initialContent: {
                type: Object as PropType<WanderlistListCard>,
                default: undefined
            }
        },

        data() {
            return {
                destinationMoreText: (this.initialContent.destinations.length === 1) ? this.initialContent.destinations[0] : `${this.initialContent.destinations[0]} + ${this.initialContent.destinations.length - 1} more`,
                destinationSet: this.initialContent.destinations,
                extraImages: [] as CaptionedImage[],
                listCardLink: cobrandLink(`/wanderlist/vcomlist/${this.initialContent.listId}`),
                listId: this.initialContent.listId,
                listNameText: escapeHTML(this.initialContent.title),
                noImageDisplay: {
                    altText: "Airplane over clouds",
                    imageUrl: "https://virtuoso-prod.dotcms.cloud/images/wanderlist/list-default-grid.jpg"
                },
                 
                primaryImage: {} as CaptionedImage,
                wlUsers: this.initialContent.companions
            };
        },
        mounted: function (): void {
            if (!this.initialContent.images || !this.initialContent.images.length) {
                this.primaryImage = this.noImageDisplay;
            } else {
                this.primaryImage = this.initialContent.images[0];
                this.extraImages = this.initialContent.images.slice(1, 5); // 0-4 of the rest of the images
            }
        }
    });
</script>
