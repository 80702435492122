<template>
    <div id="mm-list-card-display">
        <div class="list-card-display-container container mt-5 px-lg-0">
            <div class="d-flex align-items-center">
                <h1 class="text--sans-serif pe-2 mb-0">Wanderlist</h1>
                <a class="-header-add text-decoration-none" :href="createListLink">+</a>
            </div>
            <!-- isLoading Spinner -->
            <div v-if="isLoading" class="v-loading -no-overlay"></div>
            <template v-else>
                <div v-if="!isConsumer" class="mt-1">
                    <ul class="list-type-tabs list-unstyled d-flex">
                        <li v-if="isAdvisor || isVStaff" id="mm-tab-client"><button :class="tabClassClient" @click="showTab('client')">Client</button></li>
                        <li v-if="isAdvisor || isVStaff || isNetworkUser" id="mm-tab-public"><button :class="tabClassPublic" @click="showTab('public')">Public</button></li>
                        <li v-if="isAdvisor || isVStaff || isNetworkUser" id="mm-tab-drafts"><button :class="tabClassDrafts" @click="showTab('drafts')">Drafts</button></li>
                    </ul>
                </div>
                <div class="mt-2 mm-category-lists-container">
                    <no-lists-card-component v-if="!theCategoryList.length"></no-lists-card-component>
                    <div v-else>
                        <ul class="mm-category-lists">
                            <li v-for="listCard in theCategoryList" :key="listCard.listId">
                                <list-card-component :initial-content="listCard"></list-card-component>
                            </li>
                        </ul>
                        <ul class="list-unstyled mb-2">
                            <li class="text-center"><a v-if="seeButton" :href="seeWlListLink" class="btn btn-sm btn-primary-regular">See All Wanderlists</a></li>
                        </ul>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>


<script lang="ts">
    /**
    * Title: list-card-display.vue
    * Description: The mega-menu list-card-display Vue component.
    * Author: Rob Hill
    * Date: 08/08/2023
    * Version: Mega Menu Phase 1
    */
    import { WanderlistCategorizedLists, WanderlistListCard } from "interfaces/wanderlist";
    import { isAdvisor, isConsumer, isNetworkUser, isVStaff } from "modules/user-info";
    import { cobrandLink } from "virtuoso-shared-web-ui";
    import { PropType, defineComponent, reactive } from "vue";
    import ListCardComponent from "vue-components/wanderlist/list-card.vue";
    import NoListsCardComponent from "vue-components/wanderlist/no-lists-card.vue";

    export default defineComponent({
        components: {
            ListCardComponent,
            NoListsCardComponent
        },

        props: {
            isLoading: {
                type: Boolean,
                default: false
            },
            wlCatLists: {
                type: Object as PropType<WanderlistCategorizedLists>,
                default: () => ({})
            }
        },
        data() {
            return {
                createListLink: cobrandLink(`/wanderlist/my-lists/`),
                isAdvisor: isAdvisor(),
                isConsumer: isConsumer(),
                isNetworkUser: isNetworkUser(),
                isVStaff: isVStaff(),
                tabClassClient: "",
                tabClassDrafts: "",
                tabClassPublic: "",
                theCategoryList: reactive([]) as WanderlistListCard[],
                seeWlListLink: cobrandLink("/wanderlist/my-lists")
            };
        },
        computed: {
            seeButton: function (): boolean {
                return this.theCategoryList.length > 0;
            }
        },
        watch: {
            wlCatLists: function (): void {
                this.showTab("client"); // If the data isn't loaded by the time this is mounted, this ensures the first tab shows any lists
            }
        },
        mounted: function (): void {
            this.showTab("client");
        },
        methods: {
            showTab(tabName: string): void {
                this.tabClassClient = (tabName === "client") ? "-active" : "";
                this.tabClassDrafts = (tabName === "drafts") ? "-active" : "";
                this.tabClassPublic = (tabName === "public") ? "-active" : "";
                this.switchTheCategory(tabName);
            },
            switchTheCategory(labelName = ""): void {
                this.theCategoryList = (() => {
                    switch (labelName) {
                        case "client":
                            return this.wlCatLists.clientLists;
                        case "drafts":
                            return this.wlCatLists.draftLists;
                        case "public":
                            return this.wlCatLists.publicLists;
                        default: return [] as WanderlistListCard[];
                    }
                })();

                $(".mm-category-lists-container").scrollTop(0);
            }
        }
    });
</script>
