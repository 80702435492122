<template>
    <a :href="createListLink" class="-no-list-card">
        <div class="w-75">
            <h2>Explore. Dream. Share</h2>
            <p>Find new ideas, add to a Wanderlist, invite friends to collaborate</p>
        </div>
        <div class="align-self-center">
            <i class="icon-plus-square"></i>
        </div>

    </a>
</template>


<script lang="ts">
    /**
    * Title: list-card.vue
    * Description: list-card Vue component for mega-menu feature.
    * Author: Rob Hill
    * Date: 08/14/2023
    * Version: Mega Menu Phase 1
    */
    import { WanderlistListCard } from "interfaces/wanderlist";
    import { cobrandLink } from "virtuoso-shared-web-ui";
    import { PropType, defineComponent } from "vue";

    export default defineComponent({
        props: {
            categoryList: {
                type: Array as PropType<WanderlistListCard[]>,
                default: (): WanderlistListCard[] => []
            }
        },
        data() {
            return {
                createListLink: cobrandLink(`/wanderlist/my-lists/`)
            };
        }
    });
</script>
